export enum CarTransmissionTypeEnum {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
  SEMI_AUTOMATIC = "semi-automatic",
  VARIATOR = "variator",
  TIPTRONIC = "tiptronic",
  ROBOT = "robot",
}

export enum CarEngineTypeEnum {
  PETROL = "petrol",
  DIESEL = "diesel",
  ELECTRIC = "electric",
  HYBRID = "hybrid",
  GAS = "gas",
}

export const useCarsSettings = () => {
  const getCarTransmissionType = computed(() => {
    return [
      { value: CarTransmissionTypeEnum.MANUAL, label: "Механіка" },
      { value: CarTransmissionTypeEnum.AUTOMATIC, label: "Автомат" },
      { value: CarTransmissionTypeEnum.SEMI_AUTOMATIC, label: "Напівавтомат" },
      { value: CarTransmissionTypeEnum.VARIATOR, label: "Варіатор" },
      { value: CarTransmissionTypeEnum.TIPTRONIC, label: "Тіптронік" },
      { value: CarTransmissionTypeEnum.ROBOT, label: "Робот" },
    ];
  });

  const getCarEngineType = computed(() => {
    return [
      { value: CarEngineTypeEnum.PETROL, label: "Бензин" },
      { value: CarEngineTypeEnum.DIESEL, label: "Дизель" },
      { value: CarEngineTypeEnum.ELECTRIC, label: "Електро" },
      { value: CarEngineTypeEnum.HYBRID, label: "Гібрид" },
      { value: CarEngineTypeEnum.GAS, label: "Газ" },
    ];
  });

  return {
    getCarTransmissionType,
    getCarEngineType,
  };
};
